<template>
  <div class="head fixed">
    <div class="content">
      <div class="logo">
        <img :src="logo" alt="LOGO" v-if="logo" />
        <span v-else>LOGO</span>
      </div>
      <ul class="nav">
        <li
          :class="{
            active: nowPath.indexOf('/teacher/calendar') !== -1,
          }"
        >
          <router-link to="/teacher/calendar">{{ $t('teacher.calendar') }}</router-link>
          <!-- 日程表 -->
        </li>
        <li
          :class="{
            active: nowPath.indexOf('/teacher/online') !== -1,
          }"
        >
          <router-link to="/teacher/online">{{ $t('teacher.online_courses') }}</router-link>
          <!-- 线上课程 -->
        </li>
        <li
          :class="{
            active: nowPath.indexOf('/teacher/offline') !== -1,
          }"
        >
          <router-link to="/teacher/offline/index">{{ $t('teacher.offline_class_schedule') }}</router-link>
          <!-- 线下授课表 -->
        </li>
        <li
          v-if="menus.includes('live')"
          :class="{
            active: nowPath.indexOf('/teacher/live') !== -1,
          }"
        >
          <router-link to="/teacher/live">{{ $t('teacher.live_class_schedule') }}</router-link>
          <!-- 直播课程表 -->
        </li>
        <li
          :class="{
            active: nowPath.indexOf('/teacher/record') !== -1,
          }"
        >
          <router-link to="/teacher/record">{{ $t('teacher.lecturer_file') }}</router-link>
          <!-- 讲师档案 -->
        </li>
      </ul>
      <div class="right">
        <a class="link" @click="toHome">{{ $t('teacher.student_area') }}</a>
        <!-- 学员专区 -->
        <div class="avatar">
          <div class="img">
            <img :src="user.portrait" :alt="$t('CM_Header')" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { upFavicon } from "@/utils/tools";
import { userInfo, companyInfo } from "@/api/user";

export default {
  name: "Head",
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();

    // 导航
    let nowPath = computed(() => route.path);

    // 获取用户信息
    const user = computed(() => store.getters.userInfo);
    userInfo().then((res) => {
      if (res.ret === 0) {
        store.dispatch("setUserInfo", res.data);
      }else{
        proxy.$message.error($t("未授权或审核未通过"));
      }
    });

    // 获取企业信息
    let companyInfoData = ref({});
    let menus = ref([]);
    let logo = ref("");
    companyInfo().then((res) => {
      if (res.ret == 0) {
        companyInfoData.value = res.data;
        menus.value = res.data.menu;
        logo.value = res.data.logo1;
        if (res.data.logo2) upFavicon(res.data.logo2);
        if (res.data.theme)
          document.body.style.setProperty("--theme", res.data.theme);
        store.dispatch("setCompanyInfo", res.data);
      }
    });

    const toHome = () => {
      router.push({
        path: "/",
      });
    };

    return {
      companyInfoData,
      menus,
      logo,
      nowPath,
      user,
      toHome,
    };
  },
};
</script>

<style>
.ant-modal-confirm-btns .ant-btn-primary {
  background: var(--theme) !important;
  border-color: var(--theme) !important;
}
.ant-modal-confirm-btns .ant-btn:hover,
.ant-modal-confirm-btns .ant-btn:focus {
  color: var(--theme) !important;
  border-color: var(--theme) !important;
}
.ant-modal-confirm-btns .ant-btn-primary:hover,
.ant-modal-confirm-btns .ant-btn-primary:focus {
  color: #fff !important;
}
</style>
<style lang="less" scoped>
.head {
  height: 64px;
  background-color: #ffffff;
  z-index: 999;
  .content {
    height: 100%;
    .mixinWrap();
    .mixinFlex(space-between; center);
    .logo {
      .mixinImgWrap(132px; 32px);
      span {
        font-size: 18px;
        letter-spacing: 4px;
        .mixinFlex(center; center);
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
      }
    }
    .nav {
      .mixinFlex(flex-start; center);
      width: calc(100% - 406px);
      padding-left: 20px;
      box-sizing: border-box;
      li {
        font-size: 16px;
        font-weight: bold;
        &:not(:last-child) {
          margin-right: 20px;
        }
        a {
          padding: 7px 10px;
          display: inline-block;
        }
        &.active {
          a {
            color: @color-theme;
          }
        }
      }
    }
    .right {
      .mixinFlex(flex-end; center);
      width: 162px;
      .link {
        margin-left: 24px;
      }
      .avatar {
        width: 36px;
        height: 64px;
        padding: 14px 0;
        margin-left: 24px;
        position: relative;
        .img {
          .mixinImgWrap(36px; 36px);
          border-radius: 50%;
        }
      }
    }
  }
}
</style>
