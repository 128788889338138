<template>
  <a-modal
    v-model:visible="visible"
    :maskClosable="false"
    :keyboard="false"
    :closable="false"
    :footer="null"
    width="960px"
    :bodyStyle="{ padding: '24px 260px' }"
  >
    <template #title>
      <span>修改密码</span>
    </template>
    <p style="font-size: 16px; color: #333333">输入新密码</p>
    <p style="font-size: 14px; color: #999999">为提高账号安全，{{changePasswordTip}}必须修改原始密码。</p>
    <a-form
      name="custom-validation"
      ref="formRef"
      :model="formState"
      :rules="rules"
      @finish="handleFinish"
    >
      <a-form-item has-feedback name="newpass">
        <a-input-password
          v-model:value="formState.newpass"
          type="password"
          size="large"
          autocomplete="off"
          placeholder="输入新密码"
        />
      </a-form-item>
      <a-form-item has-feedback name="newpass2">
        <a-input-password
          v-model:value="formState.newpass2"
          type="password"
          size="large"
          autocomplete="off"
          placeholder="确认新密码"
        />
      </a-form-item>
      <a-form-item :wrapper-col="{ span: 12, offset: 6 }">
        <a-button type="primary" :loading="confirmLoading" html-type="submit"
          >确定</a-button
        >
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { reactive, getCurrentInstance, ref, watch } from "vue";
import { validatorPassword } from "@/utils/formRules";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import ls from "@/utils/local-storage";
import { pwdEdit } from "@/api/user";
export default {
  name: "changePassword",
  setup(props) {
    const { proxy } = getCurrentInstance();
    const route = useRoute();
    const store = useStore();
    const confirmLoading = ref(false);
    const formRef = ref();
    const formState = reactive({
      oldpass: "",
      newpass: "",
      newpass2: "",
    });
    const changePasswordTip = ref('');

    let visible = ref(false);
    watch(
      () => route.path,
      () => {
        if (route.meta.requireAuth) {
          visible.value = ls.get("changePassword") || ls.get("passwordExpire");
          if (ls.get("changePassword")) changePasswordTip.value = '第一次登录账号';
          if (ls.get("passwordExpire")) changePasswordTip.value = '登录密码过期';
        }
      },
      { immediate: true, deep: true }
    );

    let validatePass2 = async (rule, value) => {
      if (value === "") {
        return Promise.reject("请输入确认密码");
      } else if (value !== formState.newpass) {
        return Promise.reject("两次密码不一致");
      } else {
        return Promise.resolve();
      }
    };

    const rules = {
      newpass: [
        {
          required: true,
          message: "请输入新密码",
          trigger: "change",
        },
        {
          validator: validatorPassword,
          trigger: "change",
        },
      ],
      newpass2: [
        {
          required: true,
          validator: validatePass2,
          trigger: "change",
        },
      ],
    };

    const handleFinish = (values) => {
      confirmLoading.value = true;
      pwdEdit({
        newpass: proxy.$getRsaCode(formState.newpass),
      })
        .then((res) => {
          if (res.ret === 0) {
            visible.value = false;
            store.dispatch("setChangePassword", false);
            store.dispatch("setPasswordExpire", false);
            proxy.$message.success("修改成功！");
          }
        })
        .finally((res) => {
          confirmLoading.value = false;
        });
    };
    return {
      visible,
      changePasswordTip,
      confirmLoading,
      formState,
      formRef,
      rules,
      handleFinish,
    };
  },
};
</script>

<style lang="less" scoped>
.ant-form-item {
  margin-bottom: 26px;
}
.ant-btn {
  margin: 30px 0 20px;
  padding: 15px 87px;
  height: 44px;
  line-height: 14px;
}
</style>