import ls from "@/utils/local-storage";
import router from '@/router'
import store from "@/store";
import { userInfo } from "@/api/user";
import i18n from "@/locale/i18n";

export const successCallback = (res, _this) => {
  store.dispatch("setToken", res.data.token);
  let toLoginTime = parseInt(res.data.expiresIn / 60 - 30);
  let curTime = new Date();
  let expiredate = new Date(
    curTime.setMinutes(curTime.getMinutes() + Number(toLoginTime))
  );
  ls.set("TokenExpire", JSON.stringify(Date.parse(expiredate))); //多少分钟后过期！
  ls.set("refreshToken", res.data.refreshToken);
  if (res.data.isAdmin) {
    store.dispatch("setAdminLink", location.origin + "/manage/");
  }
  if (res.data.changePassword) {
    store.dispatch("setChangePassword", true);
  }
  if (res.data.passwordExpire) {
    store.dispatch("setPasswordExpire", true);
  }
  
  // 多语言
  store.dispatch("setLang", i18n.global.locale.value);

  userInfo().then((data) => {
    if (data.ret === 0) {
      store.dispatch("setUserInfo", data.data);
      let redirect = _this.redirect;
      console.log(redirect)
      if (process.env.VUE_APP_CLIENT == 'htsy') {
        if (redirect && redirect != '/' && redirect != '%2F') {
          location.href = location.origin + redirect;
        } else if (res.data.isThree) {
          location.href = location.origin + "/manage/";
        } else {
          router.replace({ path: '/' });
        }
      } else {
        if (redirect.indexOf('manage') != -1) {
          location.href = location.origin + redirect;
        } else {
          router.replace({ path: redirect.split("?")[0], query: parseQueryString(redirect) });
        }
      }
    }else{
      alert("未授权或审核未通过");
    }
  });
}

function parseQueryString(url) {
  var params = {};
  var arr = url.split("?");
  if (arr.length <= 1) {
    return params;
  }
  arr = arr[1].split("&");
  for (var i = 0, l = arr.length; i < l; i++) {
    var a = arr[i].split("=");
    params[a[0]] = a[1];
  }
  return params;
}