export default [
    {
        path: '/teacher/calendar',
        name: 't_calendar',
        meta: {
            title: 'teacher.calendar',
            requireAuth: true,
            keepAlive: false
        },
        component: () => import('@/views/teacher/calendar.vue')
    },
    {
        path: '/teacher/online',
        name: 't_online',
        meta: {
            title: 'teacher.online_courses',
            requireAuth: true,
            keepAlive: false
        },
        component: () => import('@/views/teacher/online.vue')
    },
    {
        path: '/teacher/offline/index',
        name: 't_offline',
        meta: {
            title: 'teacher.offline_class_schedule',
            requireAuth: true,
            keepAlive: false
        },
        component: () => import('@/views/teacher/offline/index.vue')
    },
    {
        path: '/teacher/offline/detail',
        name: 't_offline_detail',
        meta: {
            title: 'teacher.offline_class_schedule',
            requireAuth: true,
            keepAlive: false
        },
        component: () => import('@/views/teacher/offline/detail.vue')
    },
    {
        path: '/teacher/record',
        name: 't_record',
        meta: {
            title: 'teacher.lecturer_file',
            requireAuth: true,
            keepAlive: false
        },
        component: () => import('@/views/teacher/record.vue')
    },
    {
        path: '/teacher/applyDetail',
        name: 't_applyDetail',
        meta: {
            title: 'teacher.lecturer_application_record',
            requireAuth: true,
            keepAlive: false
        },
        component: () => import('@/views/teacher/applyDetail.vue')
    },
    {
        path: '/teacher/live',
        name: 't_live',
        meta: {
            title: 'teacher.live_class_schedule',
            requireAuth: true,
            keepAlive: false
        },
        component: () => import('@/views/teacher/live.vue')
    },
]