import JSEncrypt from 'jsencrypt';

const pubKey = `MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAtW6PFpOJD5FeVJ/+i5gW00T4GIlc246k1p3eeEaHOfv8Yr1j5gYKz0jzwn2pI9AADQS+V0StoeOuBqQa8G+k9vxF9ll9lGKHJ7EKA0YaWtyliq5tRln3ohEZknoolTfIluY3paHV4l3vCZfP/OVW8v5akdr209Hil2ELUN8wtafWqpFhMosyD6qUqCyGBFNlwmlVyxOMpTZGzGWMdQu5h+OiRr10EIl5kNSbEiKSxlwOTo5ijFDe9CHLzo43GvOUgNzghHVjmH0OEk4M2Z2QgZhzx8ORIXZew11QpfrzsXcfWVfdJxy7B6vmy0x4LBao00PZi3Dta4D+Ry+5W4Af6wIDAQAB`;
export default (app) => {
    app.config.globalProperties.$getRsaCode = (str) => {
        let encryptStr = new JSEncrypt();
        encryptStr.setPublicKey(pubKey);
        let data = encryptStr.encrypt(str.toString());
        return data;
    }
}
