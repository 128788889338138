export const mutations = {
    getLang(state, lang) {
        state.lang = lang
    },
    getLangArr(state, langArr) {
        state.langArr = langArr
    },
    getToken(state, code) {
        state.token = code
    },
    getCompanyInfo(state, data) {
        state.companyInfo = data
    },
    getPlatformConfig(state, data) {
        state.platformConfig = data
    },
    getAdminLink(state, data) {
        state.adminLink = data
    },
    getPlatform(state, data) {
        state.platform = data
    },
    getChangePassword(state, code) {
        state.changePassword = code
    },
    getPasswordExpire(state, code) {
        state.passwordExpire = code
    },
    getUserInfo(state, data) {
        state.userInfo = data
    },
    getMsgCount(state, n) {
        state.msgCount = n
    },
    getIsTeacher(state, data) {
        state.isTeacher = data
    },
    getOtherBanner(state, data) {
        state.otherBanner = data
    },
    getIsExternal(state, data) {
        state.isExternal = data
    },
};
