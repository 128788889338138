<template>
  <TeacherHead />
  <section class="app-main">
    <router-view :key="key" />
  </section>
  <Foot />
</template>

<script>
import { computed } from "vue";
import { useRoute } from "vue-router";
import TeacherHead from "@/components/layout/TeacherHead.vue";
import Foot from "@/components/layout/Foot.vue";
export default {
  name: "AppMain",
  components: {
    TeacherHead,
    Foot,
  },
  setup() {
    const route = useRoute();

    let key = computed(() => route.path);

    return {
      key,
    };
  },
};
</script>

<style lang="less" scoped>
.app-main {
  background-color: @color-page-light;
  min-height: calc(100vh - 246px);
}
</style>
