import { createI18n } from 'vue-i18n'
import ls from "@/utils/local-storage";
import messages from './index'
const defaultLang = ls.get('locale') || 'zh-CN';
const i18n = createI18n({
  fallbackWarn: false,
  globalInjection: true,
  legacy: false,
  locale: defaultLang,
  messages,
});

export default i18n