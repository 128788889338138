import { STORAGE_TOKEN_KEY } from '@/store/mutation-type';
import ls from '@/utils/local-storage';

export const actions = {
    setLang(context, lang) {
        ls.set("locale", lang);
        context.commit('getLang', lang);
    },
    setLangArr(context, langArr) {
        ls.set("langArr", langArr);
        context.commit('getLangArr', langArr);
    },
    setToken(context, code) {
        ls.set(STORAGE_TOKEN_KEY, code);
        context.commit('getToken', code);
    },
    setCompanyInfo(context, data) {
        ls.set('companyInfo', data);
        context.commit('getCompanyInfo', data);
    },
    setPlatformConfig(context, data) {
        ls.set('platformConfig', data);
        context.commit('getPlatformConfig', data);
    },
    setAdminLink(context, url) {
        ls.set('adminLink', url);
        context.commit('getAdminLink', url);
    },
    setPlatform(context, data) {
        ls.set('platform', data);
        context.commit('getPlatform', data);
    },
    setChangePassword(context, code) {
        ls.set('changePassword', code);
        context.commit('getChangePassword', code);
    },
    setPasswordExpire(context, code) {
        ls.set('passwordExpire', code);
        context.commit('getPasswordExpire', code);
    },
    setUserInfo(context, data) {
        ls.set('userInfo', data);
        context.commit('getUserInfo', data);
    },
    setMsgCount(context, n) {
        context.commit('getMsgCount', n);
    },
    setIsTeacher(context, data) {
        ls.set('isTeacher', data);
        context.commit('getIsTeacher', data);
    },
    setOtherBanner(context, data) {
        ls.set('otherBanner', data);
        context.commit('getOtherBanner', data);
    },
    setIsExternal(context, data) {
        ls.set('isExternal', data);
        context.commit('getIsExternal', data);
    },
};
