import { judgePassword } from "@/utils/tools";
import ls from "@/utils/local-storage";
import i18n from "@/locale/i18n";

// 手机号
export const validatorMobile = (rule, value, callback) => {
  if (value && !/^1[3456789]\d{9}$/.test(value)) {
    return Promise.reject(i18n.global.t('login.mobile_err'));
    // 手机号不正确
  }
  return Promise.resolve();
};

// 帐号
export const validatorAccount = (rule, value, callback) => {
  let reg = new RegExp("[\\u4E00-\\u9FFF]+", "g");
  let l = 0;
  if (reg.test(value)) { l = 1 }
  if (value && value.trim().length < 3 && l == 0) {
    return Promise.reject(i18n.global.t('login.account_regist'));
    // 请输入至少三个字符
  }
  return Promise.resolve();
};

// 邮箱
export const validatorEmail = (rule, value, callback) => {
  if (
    value &&
    !/^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,5}$/.test(
      value
    )
  ) {
    return Promise.reject(i18n.global.t('login.emial_format_err'));
    // 邮箱格式不正确
  }
  return Promise.resolve();
};

// 座机
export const validatorTel = (rule, value, callback) => {
  if (
    value &&
    !/^\d+(-\d+)*$/
      .test(
        value
      )
  ) {
    return Promise.reject(i18n.global.t('login.tel_format_err'));
    // 座机格式不正确
  }
  return Promise.resolve();
};

// 手机号或邮箱
export const validatorMobileOrEmail = (rule, value, callback) => {
  if (
    value &&
    !/^1[3456789]\d{9}$/.test(value) &&
    !/^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,5}$/.test(
      value
    )
  ) {
    return Promise.reject(i18n.global.t('login.mobile_or_email_err'));
    // 手机号或邮箱不正确
  }
  return Promise.resolve();
};

// 密码
export const validatorPassword = (rule, value, callback) => {
  const useStrongPass = ls.get("platformConfig").useStrongPass || 0;
  if (useStrongPass != 1 && value && value.length < 8) {
    return Promise.reject(i18n.global.t('login.password_len'));
    // 密码不能少于8位
  } else if (useStrongPass == 1 && value && !judgePassword(value)) {
    return Promise.reject(i18n.global.t('login.password_strong'));
    // 密码不能少于8位且需要包含大写字母、小写字母、数字和符号中的任意三项
  }
  return Promise.resolve();
};

// 中文
export const validatorCn = (rule, value, callback) => {
  if (value && !/^[\u4e00-\u9fa5]+/g.test(value)) {
    return Promise.reject(i18n.global.t('login.please_enter_chinese'));
    // 请输入中文
  }
  return Promise.resolve();
};

// 数值
export const validatorNumber = (rule, value, callback) => {
  if (value && typeof value != "number") {
    return Promise.reject(i18n.global.t('login.please_enter_the_value'));
    // 请输入数值
  }
  return Promise.resolve();
};

// null
export const validatorNull = (rule, value, callback) => {
  return Promise.resolve();
};

// 图片
export const imgRegex = /(.*)\.(jpg|bmp|gif|ico|jpeg|tif|png)$/i;
// 视频
export const videoRegex = /(.*)\.(mp4|avi|mov|rmvb|rm|3gp|flv)$/i;
// 音频
export const audioRegex = /(.*)\.(mp3|wav)$/i;
// view window.open
export const viewRegex =
  /(.*)\.(jpg|bmp|gif|ico|jpeg|tif|png|mp4|avi|mov|rmvb|rm|3gp|flv|mp3|wav|pdf)$/i;
