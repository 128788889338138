import ls from "@/utils/local-storage";
import i18n from "../locale/i18n";

export const fileType = [
  {
    name: i18n.global.t('Pub_Video'),
    id: 1,
  },
  {
    name: i18n.global.t('LB_AudioFrequency'),
    id: 2,
  },
  {
    name: i18n.global.t('Pub_Document'),
    id: 3,
  },
  {
    name: i18n.global.t('XB_DocumentText'),
    id: 9,
  },
  {
    name: i18n.global.t('LB_Picture'),
    id: 4,
  },
  {
    name: "H5",
    id: 6,
  },
  {
    name: "Scorm",
    id: 10,
  },
]

export const getFileType = (type) => {
  if (type == 1) {
    return i18n.global.t('Pub_Video'); // 视频
  } else if (type == 2) {
    return i18n.global.t('LB_AudioFrequency'); // 音频
  } else if (type == 3) {
    return i18n.global.t('Pub_Document'); // 文档
  } else if (type == 4) {
    return i18n.global.t('LB_Picture'); // 图片
  } else if (type == 5) {
    return i18n.global.t('XB_Text'); // 文档
  } else if (type == 6) {
    return "H5"; // H5
  } else if (type == 10) {
    return "Scorm"; // Scorm
  } else if (type == 8) {
    return "Url"; // Url
  } else if (type == 9) {
    return i18n.global.t('XB_DocumentText'); // 文本
  }
};

export const getDetailType = val => {
  let detailTypeEnum = {
    1: i18n.global.t('Pub_Tab_Knowledge'), // 知识
    2: i18n.global.t('CM_Curriculum'), // 课程
    3: i18n.global.t('Sys_Exam'), // 考试
    4: i18n.global.t('XB_QuestionnaireSurvey'), // 调查问卷
    5: i18n.global.t('Sys_Homework'), // 实操作业
    6: i18n.global.t('LB_Favorite_Score'), // 评分
    7: i18n.global.t('Pub_Tab_Assessment'), // 评估表
    8: i18n.global.t('Pub_Tab_CT'), // 面授
    9: i18n.global.t('Sys_WebCast'), // 直播
    10: i18n.global.t('Lab_News_News'), // 新闻
    11: i18n.global.t('activity.vote'), // 投票
    15: i18n.global.t('Lab_LessonDetail_T_SignIn'), // 签到
    16: i18n.global.t('Lab_LessonDetail_T_SignOut'), // 签退
    17: i18n.global.t('CM_EnterQues'), // 提问
    18: i18n.global.t('Lab_LessonInteract_Photograph'), // 拍照
    // 19: i18n.global.t('Sys_Homework'), // 抽奖
    30: i18n.global.t('XB_Personnel'), // 人员
    // 31: i18n.global.t('Sys_Homework'), // 试题
    // 32: i18n.global.t('Sys_Homework'), // 试卷
    33: i18n.global.t('CM_Survey'), // 问卷
    34: i18n.global.t('LB_Mysinup_Project'), // 项目
    35: i18n.global.t('cm_package'), // 培训包
    36: i18n.global.t('Pub_Tab_Knowledge'), // 知识库
    37: i18n.global.t('cm_newstaff'), // 新人培训
    38: i18n.global.t('cm_subject'), // 专题
    39: i18n.global.t('cm_activity'), // 活动
    40: i18n.global.t('task_supervision'), // 任务督导
    41: i18n.global.t('demand_collection'), // 征集需求
    42: i18n.global.t('LB_Cert_Certificate'), // 证书
    43: i18n.global.t('mine.help_manage'), // 帮带
  };
  return detailTypeEnum[val.toString()];
};

export const resourceType = [
  {
    name: i18n.global.t('Pub_Tab_Knowledge'), // Pub_Tab_Knowledge
    // name: "知识",
    color: "#82C5F0",
    unit: i18n.global.t('LB_My_Ge'),
    extraArr: [],
    id: 1,
  },
  {
    name: i18n.global.t('CM_Curriculum'),
    // name: "课程",
    color: "#FF9582",
    unit: i18n.global.t('cm_door'),
    extraArr: [],
    id: 2,
  },
  {
    name: i18n.global.t('Sys_Exam'),
    // name: "考试",
    color: "#9892FF",
    unit: i18n.global.t('cm_field'),
    extraArr: [],
    id: 3,
  },
  {
    name: i18n.global.t('XB_QuestionnaireSurvey'),
    // name: "调查问卷",
    color: "#5f88af",
    unit: i18n.global.t('LB_My_Ge'),
    extraArr: [],
    id: 4,
  },
  {
    name: i18n.global.t('Sys_Homework'),
    // name: "实操作业",
    color: "#81c57b",
    unit: i18n.global.t('LB_My_Ge'),
    extraArr: [],
    id: 5,
  },
  {
    name: i18n.global.t('LB_Favorite_Score'),
    // name: "评分",
    color: "#FF9582",
    unit: i18n.global.t('LB_My_Ge'),
    extraArr: [],
    id: 6,
  },
  {
    name: i18n.global.t('Pub_Tab_Assessment'),
    // name: "评估表",
    color: "#4470ff",
    unit: i18n.global.t('LB_My_Ge'),
    extraArr: [],
    id: 7,
  },
  {
    name: i18n.global.t('Pub_Tab_CT'),
    // name: "面授",
    color: "#44ffae",
    unit: i18n.global.t('LB_My_Ge'),
    extraArr: [],
    id: 8,
  },
  {
    name: i18n.global.t('Sys_WebCast'),
    // name: "直播",
    color: "#7dcefd",
    unit: i18n.global.t('LB_My_Ge'),
    extraArr: [],
    id: 9,
  },
]
for (let i = 9; i < 49; i++) {
  resourceType[i] = {
    name: "",
    color: "",
    unit: "",
    extraArr: [],
    id: i + 1,
  }
}
resourceType[32] = {
  name: i18n.global.t('CM_Survey'),
  // name: "问卷",
  color: "#5f88af",
  unit: i18n.global.t('LB_My_Ge'),
  extraArr: [],
  id: 33,
}
resourceType[48] = {
  name: i18n.global.t('AI_Practice'),
  // name: "AI对练",
  color: "#9892FF",
  unit: i18n.global.t('LB_My_Ge'),
  extraArr: [],
  id: 49,
}

export const questionTypeEnum = {
  1: i18n.global.t('exam.single_choice'), // 单选题
  2: i18n.global.t('exam.multiple_choice'), // 多选题
  3: i18n.global.t('LB_Exam_Judgement'), // 判断题
  4: i18n.global.t('LB_Exam_BlankFilling'), // 填空题
  5: i18n.global.t('CM_QA'), // 问答题
  8: i18n.global.t('LB_Exam_Casus'), // 案例题
}

export const letterOpts = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']

export const numeralOpts = ['一', '二', '三', '四', '五', '六', '七', '八', '九', '十'];
export const myStudyTab = [
  {
    name: i18n.global.t('all_task'),
    menuType: "*",
    taskType: [],
  },
  {
    name: i18n.global.t('MN_TrainingProject_MHome'), // 项目管理+任务督导+培训实施
    menuType: "project",
    taskType: [1, 11, 14],
    // 培训项目
  },
  {
    name: i18n.global.t('Pub_LP'),
    menuType: "map",
    taskType: [6],
    // 学习地图
  },
  {
    name: i18n.global.t('cm_newstaff'),
    menuType: "newstaff",
    taskType: [9],
    // 新人培训
  },
  {
    name: i18n.global.t('cm_subject'),
    menuType: "subject",
    taskType: [5],
    // 课程专题
  },
  {
    name: i18n.global.t('Sys_Exam'),
    menuType: "exam",
    taskType: [10],
    // 考试
  },
  {
    name: i18n.global.t('daily_exam'),
    menuType: "dailyexam",
    taskType: [2],
    // 每日一练
  },
  {
    name: i18n.global.t('cm_activity'),
    menuType: "activity",
    taskType: [12],
    // 活动
  },
  {
    name: i18n.global.t('CM_Live'),
    menuType: "live",
    taskType: [20],
    // 直播
  },
  {
    name: i18n.global.t('Pub_Tab_CT'),
    menuType: "trainindex",
    taskType: [16],
    // 面授
  },
];
// 附件密级
export const htsySecret = {
  50: "非密-",
  60: "内部-",
  70: "秘密-",
  80: "机密-",
};

export const getTaskType = (val) => {
  const taskType = {
    1: i18n.global.t('MN_TrainingProject_MHome'), //     1: "培训项目",
    2: i18n.global.t('daily_exam'), //     2: "每日一练",
    5: i18n.global.t('cm_subject'), //     5: "课程专题",
    6: i18n.global.t('Pub_LP'), //     6: "学习地图",
    8: i18n.global.t('cm_package'), //     8: "培训包",
    9: i18n.global.t('cm_newstaff'), //     9: "新人培训",
    10: i18n.global.t('issued_independently'), //     10: "独立下发",
    14: i18n.global.t('cm_project'), //     14: "培训实施",
  }
  return taskType[val.toString()];
}

// 单页模块路径
export const onePageUrl = {
  1: '/oneexam', // 在线考试
  2: '/project', // 项目培训
  3: '/map', // 学习地图
}

// 首页配置菜单名称
export const menusTitleSet = (path) => {
  let locale = ls.get('locale') || 'zh-CN';
  if (locale == "zh-CN") {
    locale = "zh_CN";
  } else if (locale == "zh-TW") {
    locale = "zh_TW";
  } else if (locale == "en-US") {
    locale = "en_US";
  }
  let menusTitle = ls.get('menusTitle')
  if (!menusTitle) return false;
  switch (path) {
    case "/":
      document.title = i18n.global.t('Head.Home');
      break;
    case "/mine":
      document.title = typeof (menusTitle["center"]) == 'string' ? menusTitle["center"] : menusTitle["center"][locale];
      break;
    case "/bbs":
      document.title = typeof (menusTitle["community"]) == 'string' ? menusTitle["community"] : menusTitle["community"][locale];
      break;
    case "/course":
      document.title = typeof (menusTitle["course"]) == 'string' ? menusTitle["course"] : menusTitle["course"][locale];
      break;
    case "/enterprise":
      document.title = typeof (menusTitle["enterprise"]) == 'string' ? menusTitle["enterprise"] : menusTitle["enterprise"][locale];
      break;
    case "/knowledge":
      document.title = typeof (menusTitle["knowledge"]) == 'string' ? menusTitle["knowledge"] : menusTitle["knowledge"][locale];
      break;
    case "/lecturer":
      document.title = typeof (menusTitle["lecturer"]) == 'string' ? menusTitle["lecturer"] : menusTitle["lecturer"][locale];
      break;
    case "/map":
      document.title = typeof (menusTitle["map"]) == 'string' ? menusTitle["map"] : menusTitle["map"][locale];
      break;
    case "/news":
      document.title = typeof (menusTitle["news"]) == 'string' ? menusTitle["news"] : menusTitle["news"][locale];
      break;
    case "/newstaff":
      document.title = typeof (menusTitle["newstaff"]) == 'string' ? menusTitle["newstaff"] : menusTitle["newstaff"][locale];
      break;
    case "/package":
      document.title = typeof (menusTitle["package"]) == 'string' ? menusTitle["package"] : menusTitle["package"][locale];
      break;
    case "/project":
      document.title = typeof (menusTitle["project"]) == 'string' ? menusTitle["project"] : menusTitle["project"][locale];
      break;
    case "/shop":
      document.title = typeof (menusTitle["shop"]) == 'string' ? menusTitle["shop"] : menusTitle["shop"][locale];
      break;
    case "/signup":
      document.title = typeof (menusTitle["signup"]) == 'string' ? menusTitle["signup"] : menusTitle["signup"][locale];
      break;
    case "/subject":
      document.title = typeof (menusTitle["subject"]) == 'string' ? menusTitle["subject"] : menusTitle["subject"][locale];
      break;
  }
}

